
import { defineComponent, nextTick, ref, watch } from 'vue'
import $store from '@/store/index'
import {
  IGoogleMapsCoords,
  stylesMapSettings,
} from '@/services/GoogleMapsService/GoogleMapsSupport'
import UIError from '@/components/UI/UIError.vue'
import UILoader from '@/components/UI/UILoader.vue'

export default defineComponent({
  name: 'TrackerMap',
  components: {
    UIError,
    UILoader,
  },
  $store,
  setup() {
    const state = ref({
      isError: false,
      isLoading: true,
      latitude: $store.state.IPApiService.state.ipAddressData?.lat ?? null,
      longitude: $store.state.IPApiService.state.ipAddressData?.lon ?? null,
    })

    const trackerMap = ref()

    function initMap(coords: IGoogleMapsCoords, map: HTMLElement) {
      $store.state.GoogleMapsService.initGoogleMap(
        coords,
        map,
        stylesMapSettings
      )
    }

    watch($store.state.IPApiService, (value) => {
      state.value.isError = false
      state.value.isLoading = true

      if (value.state.ipAddressData) {
        const geolocationCoords = {
          latitude: Number(value.state.ipAddressData.lat),
          longitude: Number(value.state.ipAddressData.lon),
        }

        nextTick(() => {
          initMap(geolocationCoords, trackerMap.value)
        })
      }

      if (value.state.isError) {
        state.value.isError = true
      }

      state.value.isLoading = false
    })

    return {
      state,
      trackerMap,
    }
  },
})
