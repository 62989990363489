
import { defineComponent, ref, watch } from 'vue'
import TrackerTableItem from '@/components/Tracker/TrackerTableItem.vue'
import UIError from '@/components/UI/UIError.vue'
import UILoader from '@/components/UI/UILoader.vue'
import $store from '@/store/index'

export default defineComponent({
  name: 'TrackerTable',
  components: {
    TrackerTableItem,
    UIError,
    UILoader,
  },
  $store,
  setup() {
    const state = ref({
      isError: false,
      isLoading: true,
      address: $store.state.IPApiService.state.ipAddressData,
    })

    watch($store.state.IPApiService, (value) => {
      state.value.isError = false
      state.value.isLoading = true

      if (value.state.ipAddressData) {
        state.value.address = value.state.ipAddressData
      }

      if (value.state.isError) {
        state.value.isError = true
      }

      state.value.isLoading = false
    })

    return {
      state,
    }
  },
})
