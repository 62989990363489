import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-373df997"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TrackerMap" }
const _hoisted_2 = {
  key: 2,
  class: "map",
  ref: "trackerMap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILoader = _resolveComponent("UILoader")!
  const _component_UIError = _resolveComponent("UIError")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.isLoading)
      ? (_openBlock(), _createBlock(_component_UILoader, { key: 0 }))
      : (_ctx.state.isError)
        ? (_openBlock(), _createBlock(_component_UIError, { key: 1 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, null, 512))
  ]))
}