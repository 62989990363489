import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8abe15fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Tracker" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrackerSearch = _resolveComponent("TrackerSearch")!
  const _component_TrackerTable = _resolveComponent("TrackerTable")!
  const _component_TrackerMap = _resolveComponent("TrackerMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('Tracker.title')), 1),
    _createVNode(_component_TrackerSearch),
    _createVNode(_component_TrackerTable),
    _createVNode(_component_TrackerMap)
  ]))
}