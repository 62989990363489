
import { defineComponent, ref } from 'vue'
import UIButton from '@/components/UI/UIButton.vue'
import UIIcon from '@/components/UI/UIIcon.vue'
import UIInput from '@/components/UI/UIInput.vue'
import $store from '@/store/index'

export default defineComponent({
  name: 'Tracker',
  components: {
    UIButton,
    UIIcon,
    UIInput,
  },
  $store,
  setup() {
    const valueInput = ref('')

    function getIPAddressData(): void {
      if (valueInput.value !== '') {
        $store.state.IPApiService.getIPAddressData(valueInput.value)
      }
    }

    return {
      valueInput,
      getIPAddressData,
    }
  },
})
