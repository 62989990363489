import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c79cd908"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TrackerTable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILoader = _resolveComponent("UILoader")!
  const _component_UIError = _resolveComponent("UIError")!
  const _component_TrackerTableItem = _resolveComponent("TrackerTableItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.state.isLoading)
      ? (_openBlock(), _createBlock(_component_UILoader, { key: 0 }))
      : (_ctx.state.isError)
        ? (_openBlock(), _createBlock(_component_UIError, {
            key: 1,
            class: "error"
          }))
        : (_ctx.state.address)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createVNode(_component_TrackerTableItem, {
                title: _ctx.$t('TrackerTable.title.ipAddress'),
                text: _ctx.state.address?.query ?? _ctx.$t('TrackerTable.text.unknown')
              }, null, 8, ["title", "text"]),
              _createVNode(_component_TrackerTableItem, {
                title: _ctx.$t('TrackerTable.title.location'),
                text: 
          `${_ctx.state.address?.city}, ${_ctx.state.address?.country}` ??
          _ctx.$t('TrackerTable.text.unknown')
        
              }, null, 8, ["title", "text"]),
              _createVNode(_component_TrackerTableItem, {
                title: _ctx.$t('TrackerTable.title.timezone'),
                text: _ctx.state.address?.timezone ?? _ctx.$t('TrackerTable.text.unknown')
              }, null, 8, ["title", "text"]),
              _createVNode(_component_TrackerTableItem, {
                title: _ctx.$t('TrackerTable.title.isp'),
                text: _ctx.state.address?.isp ?? _ctx.$t('TrackerTable.text.unknown')
              }, null, 8, ["title", "text"])
            ], 64))
          : _createCommentVNode("", true)
  ]))
}