import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d7de1c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "placeholder", "value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: _normalizeClass(["UIInput", _ctx.modifier]),
    name: _ctx.name,
    type: "text",
    placeholder: _ctx.placeholder,
    value: _ctx.modelValue,
    maxlength: _ctx.maxLength,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
  }, null, 42, _hoisted_1)), [
    [_directive_maska, _ctx.maskInput]
  ])
}