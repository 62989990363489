
import { defineComponent } from 'vue'
import IPAddressTracker from '@/views/IPAddressTracker.vue'

export default defineComponent({
  name: 'App',
  components: {
    IPAddressTracker,
  },
})
