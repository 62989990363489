
import { defineComponent } from 'vue'
import $store from '@/store/index'
import TrackerMap from '@/components/Tracker/TrackerMap.vue'
import TrackerSearch from '@/components/Tracker/TrackerSearch.vue'
import TrackerTable from '@/components/Tracker/TrackerTable.vue'

export default defineComponent({
  name: 'Tracker',
  components: {
    TrackerMap,
    TrackerSearch,
    TrackerTable,
  },
  $store,
  created() {
    $store.state.IPApiService.getIPAddressData('83.97.23.105')
  },
})
