import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a77ff84"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["UIButton", _ctx.modifier]),
    type: "button",
    "aria-label": _ctx.name
  }, [
    _renderSlot(_ctx.$slots, "icon-left", {}, undefined, true),
    _renderSlot(_ctx.$slots, "text", {}, undefined, true),
    _renderSlot(_ctx.$slots, "icon-right", {}, undefined, true)
  ], 10, _hoisted_1))
}