
import { defineComponent } from 'vue'
import { masks, EMasks } from '@/utils/masks'

export default defineComponent({
  name: 'UIInput',
  props: {
    name: {
      type: String,
      required: true,
    },
    modifier: {
      type: String,
      default: 'primary',
    },
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
      required: true,
    },
    mask: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    let maskInput = null
    let maxLength = null

    if (props.mask === EMasks.IP_ADDRESS) {
      maskInput = masks.ipAddress
      maxLength = 15
    }

    return {
      maskInput,
      maxLength,
    }
  },
})
